import { FILTERS_CONTAINS } from "@kraftheinz/common";
import { P as PromoUtils } from "./promotion.planning.js";
import { n as normalizeComponent } from "./index.js";
import "numeral";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("filter-drawer-composer", [_c("select-filter", { key: "Cluster", attrs: { "label": "Cluster", "reference": "promotion-planning.common.clusters", "queries": [["All", false]], "source": "code", "source-label": function(opt) {
    return _vm.generateLabel(opt, ["code", "description"]);
  } } }), _c("select-filter", { key: "Category", attrs: { "label": "Category", "reference": "promotion-planning.common.categories", "queries": [["All", false]], "source": "code", "source-label": function(opt) {
    return _vm.generateLabel(opt, ["code", "description"]);
  } } }), _c("select-filter", { key: "SubCategory", attrs: { "label": "Subcategory", "reference": "promotion-planning.common.sub-categories", "queries": [["All", false]], "source": "code", "source-label": function(opt) {
    return _vm.generateLabel(opt, ["code", "description"]);
  }, "option-filter": _vm.filterSubCatOption } }), _c("select-filter", { key: "Region", attrs: { "label": "Region", "reference": "promotion-planning.common.regions", "source": "code", "source-label": "description", "span": "12" } }), _c("select-filter", { key: "State", attrs: { "label": "State", "reference": "promotion-planning.common.states", "queries": [["All", false]], "source": "code", "source-label": "description", "span": "12" } }), _c("select-filter", { key: "KeyAccount", attrs: { "default-operator": _vm.FILTERS_CONTAINS, "label": "Key Account", "reference": "promotion-planning.common.customers", "source": "keyAccountCode", "source-label": function(opt) {
    return _vm.generateLabel(opt, ["region", "keyAccountCode", "keyAccount"]);
  } } }), _c("operator-filter", { key: "RRP", attrs: { "label": "RRP", "type": "number" } }), _c("operator-filter", { key: "ListPrice", attrs: { "label": "List Price", "type": "number" } }), _c("operator-filter", { key: "COGS", attrs: { "label": "COGS", "type": "number" } }), _c("operator-filter", { key: "ShipperQty", attrs: { "label": "Shipper Qty", "type": "number" } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "FilterProduct",
  mixins: [PromoUtils],
  data() {
    return { FILTERS_CONTAINS };
  },
  methods: {
    filterSubCatOption(option) {
      return option.code != -1;
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var FilterProduct = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "promotion-planning.common.clusters", "api-url": _vm.apiUrl, "resource-id-name": "code" } }), _c("resource", { attrs: { "name": "promotion-planning.common.categories", "api-url": _vm.apiUrl, "resource-id-name": "code" } }), _c("resource", { attrs: { "name": "promotion-planning.common.sub-categories", "api-url": _vm.apiUrl, "resource-id-name": "code" } }), _c("resource", { attrs: { "name": "promotion-planning.common.regions", "api-url": _vm.apiUrl, "resource-id-name": "code" } }), _c("resource", { attrs: { "name": "promotion-planning.common.states", "api-url": _vm.apiUrl, "resource-id-name": "code" } }), _c("resource", { attrs: { "name": "promotion-planning.common.customers", "api-url": _vm.apiUrl, "resource-id-name": "keyAccountCode" } }), _c("resource", { attrs: { "name": "promotion-planning.common.products", "api-url": _vm.apiUrl } }), _c("resource", { attrs: { "name": "promotion-planning.products", "api-url": _vm.apiUrl, "redirect-route": "/promotion-planning/products" } }, [_c("filter-product")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = "";
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    FilterProduct
  },
  data() {
    return {
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
